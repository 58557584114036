import React from 'react'
import {useForm} from '@formiz/core';
import {Button, Spinner} from '@chakra-ui/react';
import {FormattedMessage} from "react-intl";

export const NextButton = (props) => {
    const form = useForm({subscribe: 'form'});
    return (
        <Button
            type="submit"
            size="md"
            width="100%"
            colorScheme="orange"
            background="black"
            isDisabled={form.isSubmitted}
            {...props}
        >
            {form.isSubmitted &&
                <Spinner size='sm' mr={1}/>
            }

            {form.isLastStep ? <FormattedMessage id="form.button.finish"/> : <FormattedMessage id="form.button.next"/>}
        </Button>
    );
};
