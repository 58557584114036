import React from 'react';
import {Box, Grid, GridItem, Container, Text} from '@chakra-ui/react';
import {PageHeader} from './PageHeader';
import {PageFooter} from "./PageFooter";
import {CheckCircleIcon} from '@chakra-ui/icons'
import {FormattedMessage} from "react-intl";

export const PageLayout = ({children}) => {
    return (
        <>
            <Box pt={0}>
                <PageHeader/>
                <Container className="main-contianer" maxW="container.lg">
                    <Grid mb={20} templateColumns="repeat(2, 1fr)">
                        <GridItem rowSpan={2} colSpan={1} pt={[0, 0, 20]}>
                            <Box className="text-icon-conatiner">
                                <Box className="icon-text" mb="6">
                                    <Box className="icon">
                                        <CheckCircleIcon w={8} h={8}/>
                                    </Box>
                                    <Box>
                                        <Text>
                                            <FormattedMessage id="start.fact.1"/>
                                        </Text>
                                    </Box>
                                </Box>
                                <Box className="icon-text" mb="6">
                                    <Box className="icon">
                                        <CheckCircleIcon w={8} h={8}/>
                                    </Box>
                                    <Box>
                                        <Text>
                                            <FormattedMessage id="start.fact.2"/>
                                        </Text>
                                    </Box>
                                </Box>
                                <Box className="icon-text" mb="6">
                                    <Box className="icon">
                                        <CheckCircleIcon w={8} h={8}/>
                                    </Box>
                                    <Box>
                                        <Text>
                                            <FormattedMessage id="start.fact.3"/>
                                        </Text>
                                    </Box>
                                </Box>
                            </Box>
                        </GridItem>
                        <GridItem className="form-wrapper" rowSpan={2}>
                            <Box className="form-container" mt={{base: 0, md: -20}} boxShadow="xl" mx="0"
                                 ml={{base: 0, md: 10}} p="8" rounded="md">
                                {children}
                            </Box>
                        </GridItem>
                    </Grid>
                </Container>

                <PageFooter/>
            </Box>
        </>
    );
};
