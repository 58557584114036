import './App.scss';
import {ChakraProvider, extendTheme} from '@chakra-ui/react'
import {Stepper} from './comnponents/Stepper';
import ReactGA from 'react-ga';
import {IntlProvider} from "react-intl";
import English from './lang/en.json';
import moment from 'moment';
import 'moment/locale/en-gb';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/700.css';

let messages = English;
let locale = 'en';
moment.locale('en');

const currentUrl = window.location.toString();

if (currentUrl.search('/ie') !== -1) {
    messages.outgoingUrl = 'https://b-extra-capsules.com/ie/order-now.html';
}

const theme = extendTheme({
    fonts: {
        heading: "'Open Sans', 'sans-serif'",
        body: "'Open Sans', 'sans-serif'",
    }
})

document.title = messages.title;

function App() {
    ReactGA.initialize('UA-183263461-14');
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <div className="App">
            <IntlProvider locale={locale} messages={messages} defaultLocale="de">
                <ChakraProvider theme={theme}>
                    <Stepper/>
                </ChakraProvider>
            </IntlProvider>
        </div>
    );
}

export default App;
